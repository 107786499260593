var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-grid-row",
    [
      _c(
        "gov-grid-column",
        { attrs: { width: "two-thirds" } },
        [
          _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("Home")]),
          _c("gov-body", [
            _vm._v("Review the content for the home page on the frontend.")
          ]),
          _c("ck-text-input", {
            attrs: {
              value: _vm.frontend.home.search_title,
              label: "Search Title",
              error: _vm.errors.get("cms.frontend.home.search_title"),
              id: "cms.frontend.home.search_title"
            },
            on: {
              input: function($event) {
                return _vm.onInput({ field: "search_title", value: $event })
              }
            }
          }),
          _c("ck-text-input", {
            attrs: {
              value: _vm.frontend.home.categories_title,
              label: "Categories Title",
              error: _vm.errors.get("cms.frontend.home.categories_title"),
              id: "cms.frontend.home.categories_title"
            },
            on: {
              input: function($event) {
                return _vm.onInput({ field: "categories_title", value: $event })
              }
            }
          }),
          _c("ck-text-input", {
            attrs: {
              value: _vm.frontend.home.personas_title,
              label: "Personas Title",
              error: _vm.errors.get("cms.frontend.home.personas_title"),
              id: "cms.frontend.home.personas_title"
            },
            on: {
              input: function($event) {
                return _vm.onInput({ field: "personas_title", value: $event })
              }
            }
          }),
          _c("ck-wysiwyg-input", {
            attrs: {
              value: _vm.frontend.home.personas_content,
              label: "Personas Content",
              error: _vm.errors.get("cms.frontend.home.personas_content"),
              id: "cms.frontend.home.personas_content"
            },
            on: {
              input: function($event) {
                return _vm.onInput({ field: "personas_content", value: $event })
              }
            }
          }),
          _vm._l(_vm.frontend.home.banners, function(banner, index) {
            return _c(
              "ck-banner-input",
              {
                key: "home-banner-" + index,
                attrs: { errors: _vm.errors.get("cms.frontend.home.banners") },
                model: {
                  value: _vm.frontend.home.banners[index],
                  callback: function($$v) {
                    _vm.$set(_vm.frontend.home.banners, index, $$v)
                  },
                  expression: "frontend.home.banners[index]"
                }
              },
              [
                _c(
                  "gov-button",
                  {
                    attrs: { type: "button", error: "" },
                    on: {
                      click: function($event) {
                        return _vm.onRemoveBanner(index)
                      }
                    }
                  },
                  [_vm._v("Remove banner")]
                )
              ],
              1
            )
          }),
          _c(
            "gov-button",
            { on: { click: _vm.onAddBanner } },
            [
              _vm.frontend.home.banners.length === 0
                ? [_vm._v("Add Home Banner")]
                : [_vm._v("Add another")]
            ],
            2
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }