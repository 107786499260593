import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=2f2f64a2&"
import script from "./Home.vue?vue&type=script&lang=js&"
export * from "./Home.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jae/code/connected-places/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2f2f64a2')) {
      api.createRecord('2f2f64a2', component.options)
    } else {
      api.reload('2f2f64a2', component.options)
    }
    module.hot.accept("./Home.vue?vue&type=template&id=2f2f64a2&", function () {
      api.rerender('2f2f64a2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/admin/index/cms/frontend/Home.vue"
export default component.exports